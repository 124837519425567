import DynamicDialog from "components/dialogs/Dialog";
import { PrintOutlined } from "../../../node_modules/@mui/icons-material/index";
import {
  Box,
  Grid,
  TextField,
} from "../../../node_modules/@mui/material/index";
import { getDialogButton } from "helpers/General";
import { useState } from "react";

export default function SummaryBox(props) {
  const { children, color, reportFunction, reportName } = props;
  const [printClicked, setPrintClicked] = useState(false);
  const [email, setEmail] = useState("");

  const userTyping = (e) => {
    setEmail(e.target.value);
  };

  const resetDialogs = () => {
    setPrintClicked(false);
  };
  const reportClicked = () => {
    resetDialogs();
    if (reportFunction) {
      reportFunction(email);
    }
  };

  var closeButton = getDialogButton("Close", resetDialogs);
  var confirmButton = getDialogButton("Submit", reportClicked);

  return (
    <Box
      sx={{
        width: "100%",
        aspectRatio: "1 / 1",
        maxHeight: "30em",
        //height: "20em",
        //  borderRadius: "2em",
        position: "relative",
        margin: "1em",
        backgroundColor: color,
      }}
    >
      <PrintOutlined
        onClick={() => setPrintClicked(true)}
        style={{
          position: "absolute",
          right: 0,
          margin: ".25em",
          cursor: "pointer",
          textDecoration: "underline"
        }}
      />
      {children}
      <DynamicDialog
        maxWidth="lg"
        fullWidth
        buttons={[confirmButton, closeButton]}
        open={printClicked}
        title="Send report to email"
        //children={<HomeDetailsCard {...homeDetails} />}
        children={
          <Grid container>
            <Grid xs={12} item>
              Enter an email to recieve the {reportName ? reportName : ""}{" "}
              report
            </Grid>
            <Grid xs={12} item>
              <TextField
                label="Enter Email"
                inputProps={{
                  onChange: userTyping,
                }}
                id="search"
                formcontrolprops={{
                  fullWidth: true,
                }}
              />
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
}
