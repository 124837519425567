import { Grid, Typography } from "@mui/material";
import TableHeaderDatePicker from "components/cards/dashboard/TableHeaderDatePicker";
import HomeDetailsCard from "components/cards/home/HomeDetailsCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "../../../node_modules/moment/moment";
import {
  getDateRange,
  COPPER_DATA_MISSING_REVISIT,
  CX_PARTIAL_COMPLETE,
  NETWORK_ONT_MISSING_WG,
  BUILDER_ISSUES,
  CX_COMPLETE,
  HARDWARE_PREPPED_WAITING_ON_BUILDER,
  HS_BOX_CONFIGURED,
  IN_REVIEW_AXIOS,
  LT_GREEN_MISSINGPAIR_RACHIO,
  NO_VISIT,
  KeyToColor,
  defaultDate,
} from "helpers/Service";

import { dispatch } from "store/index";
import { updateLoading } from "store/reducers/mask";
import { fetchHomeData, fetchUser } from "helpers/SrApiCalls";
import HomesWidget from "components/widgets/homesWidget";
import { Box, Button } from "../../../node_modules/@mui/material/index";
import TabViewContainer from "components/containers/tabViewContainer";

import SummaryWidget from "components/widgets/summaryWidget";
import HomeClosedSummarySquare from "components/cards/dashboard/HomeClosedSummarySquare";
import { FetchHomesDateFilter } from "components/widgets/fetchHomesDateFilter";
import UtilitiesSummarySquare from "components/cards/dashboard/UtilitiesSummarySquare";
import BuilderDeficienciesSummarySquare from "components/cards/dashboard/BuilderDeficienciesSummarySquare";
import { DASHBOARD_MASK_ID } from "constants/api";
import SRButton from "components/buttons/index";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DynamicDialog from "components/dialogs/Dialog";
import { getDialogButton } from "helpers/General";
import BuilderDiscrepancySummarySquare from "components/cards/dashboard/BuilderDiscrepancySummarySquare";

const Dashboard = () => {
  const [homes, setHomes] = useState([]);
  const [openByDefault, setOpenByDefault] = useState(true);

  const [oldCalTime, setoldCalTime] = useState("");
  const [oldHouseTime, setolHouseTime] = useState("");
  const [showUpdatedPopup, setshowUpdatedPopup] = useState(false);

  const [showUpdatedPopupMessage, setshowUpdatedPopupMessage] = useState("");

  const [dateRange, setDateRange] = useState(defaultDate());

  const fetchHomes = async () => {
    dispatch(updateLoading({ loading: true, fromId: DASHBOARD_MASK_ID }));
    //console.log('showing loading mask .....');
    var newHomes = await fetchHomeData(dateRange);

    if (newHomes) {
      setHomes(newHomes.allHomes);
    } else {
      console.log("Fetching categories failed");
    }
    //console.log("hiding dispatch.....");
    dispatch(updateLoading({ loading: false, fromId: DASHBOARD_MASK_ID }));
  };

  const resetDialogs = () => {
    setshowUpdatedPopup(false);
  };
  var closeButton = getDialogButton("Close", resetDialogs);

  const refetchData = (olduser, dispatch) => {
    dispatch(updateLoading({ loading: true, fromId: DASHBOARD_MASK_ID }));
    let oldUserStatus = {};
    if (olduser && olduser?.srApplicationEventLogs) {
      oldUserStatus = Object.assign(
        {},
        ...olduser?.srApplicationEventLogs?.map(({ eventTitle, dateTime }) => ({
          [eventTitle]: dateTime,
        })),
      );
    }
    const oldCal = "" + oldUserStatus["Closing Calendar Events Updated"];

    const oldHouse = "" + oldUserStatus["Checklist Details Updated"];
    setolHouseTime(oldHouse);
    setoldCalTime(oldCal);

    fetchUser({ id: olduser.id }, dispatch).finally(() => {
      dispatch(updateLoading({ loading: false, fromId: DASHBOARD_MASK_ID }));
    });
  };

  useEffect(() => {
    fetchHomes();
  }, [dateRange, openByDefault]);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (oldCalTime || oldHouseTime) {
      let oldUserStatus = {};
      if (user && user?.srApplicationEventLogs) {
        oldUserStatus = Object.assign(
          {},
          ...user?.srApplicationEventLogs?.map(({ eventTitle, dateTime }) => ({
            [eventTitle]: dateTime,
          })),
        );
      }

      const newCal = "" + oldUserStatus["Closing Calendar Events Updated"];

      const newHouse = "" + oldUserStatus["Checklist Details Updated"];
      setshowUpdatedPopup(true);
      if (newCal !== oldCalTime || newHouse !== oldHouseTime) {
        setshowUpdatedPopupMessage("New data retrived sucessfully!");
        fetchHomes();
      } else {
        setshowUpdatedPopupMessage("No new data currently available.");
      }

      setolHouseTime("");
      setoldCalTime("");
    }
  }, [user]);

  const dis = useDispatch();
  if (user.role === "n") {
    return (
      <Grid
        container
        direction="row"
        rowSpacing={4.5}
        columnSpacing={2.75}
        padding={2}
        backgroundColor="#F2F2F2"
      >
        <Grid item xs={12}>
          <Typography variant="h5">
            You must be confirmed by a system admin before you can use this
            tool. After confirmation, please log back in to begin.
          </Typography>
        </Grid>
        <Grid item contianer xs={12}>
          <Grid item contianer xs={6}>
            {" "}
            <Typography variant="h5">Current user:</Typography>
          </Grid>{" "}
          <Grid item contianer xs={6}>
            {" "}
            <Typography variant="h5"> {user.email}</Typography>
          </Grid>
        </Grid>
        <Grid item contianer xs={12}>
          <Grid item md={1}>
            {" "}
            <Typography variant="h5">Status:</Typography>
          </Grid>{" "}
          <Grid item md={1}>
            {" "}
            <Typography variant="h5"> New User</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const onlyClosingHomes = homes.filter((x) => {
    const closing = moment(
      x?.srCalendarEvents[0]?.closingTime,
      "YYYY-MM-DDTHH:mm:ss",
    );

    return closing.isBetween(dateRange[0], dateRange[1], undefined, "[]");
  }); //homes is all homes with any event in the date range, just want homes that are closing in that range for the summaries

  const onlyClosedHomes = onlyClosingHomes.filter((x) => {
    const closing = moment(
      x?.srCalendarEvents[0]?.closingTime,
      "YYYY-MM-DDTHH:mm:ss",
    );

    return closing.isBefore();
  }); //for the demo, only going to summaries closed homes

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Grid item container xs={12} justifyContent="space-between">
          <Grid item>
            <Typography item xs={4} variant="h3">
              Report Summary Snapshots{" "}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="info"
              onClick={() => refetchData(user, dis)}
            >
              Admin database updates
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6">
            Use the start and end dates to filter the below summary sections.
            Within each summary report, there are clickable subsection. When
            clicked a table detailing information from the subsection clicked
            will display. Click the print icon in the upper right corner of any
            summary box to have a custom report emailed.
          </Typography>
        </Grid>
        {
          <Grid item xs={12} sx={{ margin: ".5em", padding: "1em" }}>
            <FetchHomesDateFilter
              sx={{ paddingBottom: "2em" }}
              setHomes={setHomes}
              setDates={setDateRange}
              setOtherCalendarEvents={null}
              dates={dateRange}
            />
          </Grid>
        }

        {homes && homes.length > 0 && (
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="space-around"
            spacing={2}
            sx={{marginLeft:"-32px"}}
          >
            <Grid item xs={3}>
              <HomeClosedSummarySquare
                homes={onlyClosedHomes}
                dates={dateRange}
              />
            </Grid>
            <Grid item xs={3}>
              <UtilitiesSummarySquare
                homes={onlyClosedHomes}
                dates={dateRange}
              />
            </Grid>
            <Grid item xs={3}>
              <BuilderDeficienciesSummarySquare
                homes={onlyClosedHomes}
                dates={dateRange}
              />
            </Grid>
            <Grid item xs={3}>
              <BuilderDiscrepancySummarySquare
                homes={onlyClosedHomes}
                dates={dateRange}
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography item xs={4} variant="h3" sx={{ marginLeft:"15px" }}>
                  Home Data Tables
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{ marginLeft:"15px" }}>
                <Typography variant="h6">
                  The below tables are filtered by tab and date selections.
                  Click any data record to see additonal details about the home.
                </Typography>
              </Grid>
              <TabViewContainer>
                <SummaryWidget
                  // homes={homes}
                  calendarType="closing"
                  label="closing summary"
                  openByDefault={openByDefault}
                />
                <SummaryWidget
                  // homes={homes}
                  calendarType="closing"
                  label="backlogged homes"
                  filter={[
                    KeyToColor[NETWORK_ONT_MISSING_WG],
                    KeyToColor[HARDWARE_PREPPED_WAITING_ON_BUILDER],
                    KeyToColor[BUILDER_ISSUES],
                    KeyToColor[LT_GREEN_MISSINGPAIR_RACHIO],
                  ]}
                />
                {/* <SummaryWidget
                  // homes={homes}
                  calendarType="all"
                  label="all home appointments"
                />*/}
                <SummaryWidget
                  // homes={homes}
                  // showOtherCalendarEvents  //only use when want pure calendar events with no associated home
                  calendarType="axios"
                  label="post closing appointments"
                />
                <SummaryWidget
                  // homes={homes}
                  calendarType="closing"
                  label="homes with freshdesk tickets"
                  filter={["hasticket"]}
                />
                {/*
                <HomesWidget
                  // homes={homes}
                  label="home cards"
                />*/}
              </TabViewContainer>
            </Grid>
          </Grid>
        )}
      </Grid>
      <DynamicDialog
        maxWidth="sm"
        fullWidth
        buttons={[closeButton]}
        open={showUpdatedPopup}
        children={
          <Typography variant="h2">{showUpdatedPopupMessage}</Typography>
        }
      />
    </Grid>
  );
};

export default Dashboard;
