import React, { useState } from "react";
import { Grid } from "@mui/material";

import {
  ADMIN_VALIDATE_HOMES,
  DOWNLOAD_HOLIG_FILE,
  DOWNLOAD_RESALE_FROM_EMAIL,
  DOWNLOAD_XO_FROM_EMAIL,
  GENERATE_XO_FROM_DB,
  GET_TWO_WEEK_TECH_REPORT_BUNDLE_DOWNLOAD,
  MAILCHIMP_WRONG_ADDRESS,
  MAKE_BUILDER_DEFICIENCY_REPORT,
  MAKE_CLA_REPORT,
  MAKE_DISCREPANCY_REPORT,
  MAKE_OVERALL_BACKLOG_REPORT,
  MAKE_SAMPLE_TECH_REPORT_CSV,
  MAKE_SAMPLE_TECH_REPORT_PDF,
  MAKE_SAMPLE_TECH_REPORT_TXT,
  MAKE_XO_MISSING_REPORT,
  SET_AVAILABLE_ALARM_IDS,
  UPDATE_COMPANY_IDS,
  UPDATE_SURVEY_RESULTS,
  UPDATE_XO_FROM_FILE,
  UPLOAD_BUILDER_CONTACTS,
  UPLOAD_GEN_3_LOGIN_CHECKLISTS,
  UPLOAD_RESALE_XLSX,
  UPLOAD_SURVEY_RESULTS,
} from "constants/api";
import DynamicDialog from "components/dialogs/Dialog";
import { getDialogButton } from "helpers/General";
import { DownloadFileButton } from "components/inputs/download-file-button";
import { UploadFileButton } from "components/inputs/upload-file-button";
import { SimpleContainer } from "components/cards/simpleContainer/simpleContainer";
import { APIButton } from "components/inputs/api-get-button";

const Admin = () => {
  const [dialogMessage, setDialog] = useState("");

  const resetDialogs = () => {
    setDialog("");
    // setFiles([]);
  };
  var cancelButton = getDialogButton("Cancel", resetDialogs);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <SimpleContainer label="Mailchimp Surveys">
        <APIButton
          apicall={UPDATE_SURVEY_RESULTS}
          label="Update Homes with Mailchimp Survey Results"
          setDialog={setDialog}
        />
        <UploadFileButton
          apicall={UPLOAD_SURVEY_RESULTS}
          setDialog={setDialog}
          label="Upload Mailchimp Survey Results CSV"
          type={".csv"}
        />

        <DownloadFileButton
          apicall={MAILCHIMP_WRONG_ADDRESS}
          fileName={
            "ADDRESS_WRONG_IN_MAILCHIMP_" + new Date().getTime() / 1000 + ".csv"
          }
          label="Check Mailchimp Users For Homes Match"
        />
      </SimpleContainer>
      <SimpleContainer label="HOLIG">
        <DownloadFileButton
          apicall={DOWNLOAD_HOLIG_FILE}
          fileName={"HOLIG_EXPORT_" + new Date().getTime() / 1000 + ".xlsx"}
          label="Download HOLIG Gen3 Export"
        />

        <UploadFileButton
          apicall={SET_AVAILABLE_ALARM_IDS}
          setDialog={setDialog}
          label="Update Unassigned Alarm360 IDs from available sheet"
        />

        <UploadFileButton
          apicall={UPLOAD_GEN_3_LOGIN_CHECKLISTS}
          setDialog={setDialog}
          label="Build and Upload new gen3 Login Checklists"
        />
      </SimpleContainer>

      <SimpleContainer label="XO">
        <DownloadFileButton
          apicall={DOWNLOAD_XO_FROM_EMAIL}
          fileName={"XO_Export" + new Date().getTime() / 1000 + ".xlsx"}
          label="Download XO Spreadsheet From Email"
        />

        <DownloadFileButton
          apicall={GENERATE_XO_FROM_DB}
          fileName={
            "XO_Database_Export" + new Date().getTime() / 1000 + ".xlsx"
          }
          label="Generate XO Spreadsheet From Database"
        />

        <UploadFileButton
          apicall={UPDATE_XO_FROM_FILE}
          setDialog={setDialog}
          label="Update XO Records From File"
        />
      </SimpleContainer>

      <SimpleContainer label="Resale">
        <UploadFileButton
          apicall={UPLOAD_RESALE_XLSX}
          setDialog={setDialog}
          label="Update Resale from .XLSX"
          type={".xlsx"}
        />
        <DownloadFileButton
          apicall={DOWNLOAD_RESALE_FROM_EMAIL}
          fileName={"RESALE_EXPORT" + new Date().getTime() / 1000 + ".xlsx"}
          label="Download Resale Spreadsheet From Email"
        />
      </SimpleContainer>

      <SimpleContainer label="Builder Contacts">
        <UploadFileButton
          apicall={UPLOAD_BUILDER_CONTACTS}
          setDialog={setDialog}
          label="Upload Builder Contact Sheet"
        />
        <APIButton
          apicall={UPDATE_COMPANY_IDS}
          setDialog={setDialog}
          label="Fetch Missing Company BimIds"
        />
      </SimpleContainer>
      <SimpleContainer label="Technician Grade Functions">
        <DownloadFileButton
          apicall={GET_TWO_WEEK_TECH_REPORT_BUNDLE_DOWNLOAD}
          fileName={
            "Tech_Report_Bundle_" +
            new Date().toISOString().substring(0, 10) +
            ".zip"
          }
          label="Download Tech Report Bundle Zip"
        />
        <DownloadFileButton
          apicall={MAKE_SAMPLE_TECH_REPORT_PDF}
          fileName={
            "ADMIN_SAMPLE_TEXT_REPORT_" +
            new Date().toISOString().substring(0, 10) +
            ".pdf"
          }
          label="Download Sample Tech Report PDF"
        />
        <DownloadFileButton
          apicall={MAKE_SAMPLE_TECH_REPORT_CSV}
          fileName={
            "ADMIN_SAMPLE_TEXT_REPORT_" +
            new Date().toISOString().substring(0, 10) +
            ".csv"
          }
          label="Download Sample Tech Report CSV"
        />
        <DownloadFileButton
          apicall={MAKE_SAMPLE_TECH_REPORT_TXT}
          fileName={
            "ADMIN_SAMPLE_TEXT_REPORT_" +
            new Date().toISOString().substring(0, 10) +
            ".txt"
          }
          label="Download Sample Tech Report TXT"
        />
      </SimpleContainer>
      <SimpleContainer label="Validation Functions">
        <APIButton
          apicall={ADMIN_VALIDATE_HOMES}
          setDialog={setDialog}
          label="Validate (Grape) CX Complete homes closed between 01-01-2021 and now"
        />
      </SimpleContainer>

      <SimpleContainer label="Report Downloads">
        <DownloadFileButton
          apicall={MAKE_XO_MISSING_REPORT}
          fileName={
            "ADMIN_XO_CONTRACT_REPORT_" + new Date().getTime() / 1000 + ".xlsx"
          }
          label="Download XO Contract Report"
        />

        <DownloadFileButton
          apicall={MAKE_BUILDER_DEFICIENCY_REPORT}
          fileName={
            "ADMIN_BUILDER_DEFICIENCY_REPORT_" +
            new Date().getTime() / 1000 +
            ".xlsx"
          }
          label="Download Builder Defiency Report"
        />

        <DownloadFileButton
          apicall={MAKE_DISCREPANCY_REPORT}
          fileName={
            "ADMIN_DISCREPANCY_REPORT_" + new Date().getTime() / 1000 + ".xlsx"
          }
          label="Download Discrepancy Report"
        />

        <DownloadFileButton
          apicall={MAKE_OVERALL_BACKLOG_REPORT}
          fileName={
            "ADMIN_OVERALL_BACKLOG_REPORT_" +
            new Date().getTime() / 1000 +
            ".xlsx"
          }
          label="Download Overall Backlog Report"
        />

        <DownloadFileButton
          apicall={MAKE_CLA_REPORT}
          fileName={"ADMIN_CLA_REPORT_" + new Date().getTime() / 1000 + ".xlsx"}
          label="Download CLA Report"
        />
      </SimpleContainer>

      <DynamicDialog
        buttons={[cancelButton]}
        open={dialogMessage !== ""}
        title={dialogMessage}
      />
    </Grid>
  );
};

export default Admin;
